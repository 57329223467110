import React from 'react';
import {withRouter} from 'react-router-dom';

import logo from '../../assets/img/logo.png';
import Progress from '../../component/Progress';
import './style.css';

const Header = ({history}) => {
  return (
    <header className="app-container-header">
      <div onClick={() => history.push('/company')} className="logo-header">
        <img src={logo}  alt="Lunch Team"/>
        <h5>LocalDelivery.team</h5>
      </div>  
      <Progress />
    </header>
  );
}

export default withRouter(Header);