import styled from 'styled-components';

const CurrencyInput = styled.div`
  display:inline-block;
  position: relative;

  &::before{
    position: absolute;
    content: "${props => props.currency}";
    left:5px;
	  top:7px;
  }

  input {
    padding-left:25px;
  }
`

export default CurrencyInput;