import React, {useContext} from 'react';

import { useForm } from '../../hooks/useForm';
import validationProduct from '../../validations/product';
import { LocationContext } from '../../context/Location';
import CurrencyInput from '../../component/CurrencyInput';

import { ModalOverlay } from '../styles';
import './styles.css';

const AddProduct = ({
  product,
  categories,
  onSaveProduct,
  close
}) => {

  const { location } = useContext(LocationContext);
  
  const generateID = () => {
    return '_' + Math.random().toString(36).substr(2, 9);
  };

  const handleClickCreate = () => {
    const id = values.id ? values.id : generateID();
    onSaveProduct({...values, id });
  }

  const { values, errors, onChange, onSubmit } = 
  useForm(
    handleClickCreate, 
    { description : '' , category: 0, price: 0, ...product}, 
    validationProduct
  );

  return (
     <ModalOverlay>
        <div style={{display : 'block'}} className="modal show" id="myModal">
          <div className="modal-dialog modal-lg modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">
                    {product ? 'Edit Product' :'Add New Product'}
                </h4>
                <button onClick={close} 
                        type="button" 
                        className="close" 
                        data-dismiss="modal">&times;
                </button>
              </div>
              <form onSubmit={onSubmit}>
              <div className="modal-body">
                <div className="form-row">
                  <div className="form-group col-md-8 required">
                    <label className="control-label" >Product Name</label>
                    <input
                      type="text"
                      className={
                        `form-control ${errors.description && "is-invalid"} `}
                      name="description"
                      value={values.description}
                      onChange={onChange}
                    />
                    {errors.description && (
                      <div className="invalid-feedback">{errors.description}</div>
                    )}
                  </div>
                  <div className="form-group col-md-4 required">                        
                    <label className="control-label" >Category</label>
                    <select 
                      className={
                        `form-control ${errors.category && "is-invalid"} `}
                      name="category"
                      onChange={onChange}
                      value={values.category.id}>
                      <option></option>
                      {
                        categories.map(item => (
                        <option key={item.id} value={item.id}>
                          {item.description}
                        </option>))
                      }
                    </select>
                    {errors.category && (
                      <div className="invalid-feedback">{errors.category}</div>
                    )}
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-md-4 required">
                    <label className="control-label" >Price</label>
                    <CurrencyInput className="priceInput" currency={location.currency}> 
                      <input
                        type="number"
                        step="any"
                        className={ 
                          `form-control ${errors.price && "is-invalid"} `}
                        name="price"
                        value={values.price}
                        onChange={onChange}
                      />
                    </CurrencyInput>
                    {errors.price && (
                      <div style={{display : 'block'}}  
                          className="invalid-feedback">{errors.price}</div>
                    )}
                  </div>
                </div>
              </div>
              <div className="add-category-footer">
                  <button className="btn orange-bold" type="submit" >
                    SAVE
                  </button>
              </div>
              </form>
            </div>
          </div>
      </div> 
    </ModalOverlay>
  );
}

export default AddProduct;