import React, { useState, useEffect } from 'react';

import { ModalOverlay } from '../styles';
import './styles.css';

const AddCategory = ({
  category,
  onSaveCategory,
  close
}) => {

  const [categoryDescription, setCategoryDescription] = useState('');

  useEffect(() => {
    if(category){
      setCategoryDescription(category.description);
    }
  }, [category]);

  const onKeyUp = (event) => {
    if (event.key === 'Enter' && event.target.value.trim(' ').length > 0) {
      onSaveCategory({...category, description: event.target.value, });
    }
  }

  const handleChangeCategory = evt => {
    evt.preventDefault();
    setCategoryDescription(evt.target.value);
  }

  const handleClickCreate = () => {
    onSaveCategory({...category, description: categoryDescription})
  }

  return (
      <ModalOverlay>
        <div style={{display : 'block'}} className="modal show" id="myModal">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">
                  {category ? 'Edit Category' : 'New Category'}
              </h4>
              <button onClick={close} 
                      type="button" 
                      className="close" 
                      data-dismiss="modal">&times;
              </button>
            </div>
            <div className="modal-body">
              <div className="form-row">
                <div className="form-group col-md-12">
                  <input
                    type="text"
                    className="form-control"
                    name="categoryDescription"
                    onKeyUp={onKeyUp}
                    value={categoryDescription}
                    placeholder="Type Category Name"
                    onChange={handleChangeCategory}
                  />
                </div>
              </div>
            </div>
            <div className="add-category-footer">
                <button className="btn orange-bold" 
                    onClick={handleClickCreate} >
                  Create
                </button>
            </div>          
          </div>
        </div>
      </div> 
    </ModalOverlay>
  );
}

export default AddCategory;