export default function validateCompany(values){
  let errors = {};

  function validateEmail(email) {
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  if (values.name.trim() === "") {
    errors.name = "Please complete this required field.";
  }

  if (values.phone.trim() === "") {
    errors.phone = "Please complete this required field.";
  }

  if(!validateEmail(values.email)){
    errors.email = "Please type a valid email." ;
  }

  if (values.address.address.trim() === "") {
    errors.address = "Please complete this required field.";
  }

  if(values.category === 0){
    errors.category = "Please complete this required field.";
  }

  return errors;
}