import React from 'react';

import Routes from './routes';

import 'react-phone-number-input/style.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';


export default function App() {
  return (
    <Routes />
  );
}
