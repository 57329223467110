import React, {createContext, useState, useEffect, useCallback} from 'react';

import {search} from '../../util/country';

export const LocationContext = createContext({
  location : {currency: '', labelLocation: '', countryCode: '', country: '', city: ''},
  setCountry: (countryCode) => {},
  clear: () => {}
});

const LocationProvider = ({children}) => {
  
  useEffect(() => {
    const location = JSON.parse(sessionStorage.getItem('location'))
    if(location){
      setLocation({...location});
    }
  }, []);

  const [location,  setLocation] = useState({
    currency: '', 
    labelLocation: '',
    countryCode: '',
    country: '', 
    city: ''});
  

  const handleSetLocationInfo = (country, labelLocation, city) => {
    const countryFound = search(country.short_name);
    if(countryFound.length > 0){
      sessionStorage.setItem('location', JSON.stringify({
        currency: countryFound[0].currency.currencySymbol, 
        labelLocation,
        city,
        countryCode: country.short_name,
        country: country.long_name,
      })); 
      setLocation({
        currency: countryFound[0].currency.currencySymbol,
        labelLocation, 
        city,
        countryCode: country.short_name, 
        country: country.long_name});
    }
  }

  const handleClear = useCallback(() => {
    sessionStorage.clear();
  }, []); 

  return(
  <LocationContext.Provider value={{
    location,
    clear: handleClear,
    handleSetLocationInfo: handleSetLocationInfo
  }}>
    {children}
  </LocationContext.Provider>);
}

export default LocationProvider;
