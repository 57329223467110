import React from 'react';
import {withRouter} from 'react-router-dom';
import './style.css';

import Logo from '../../assets/img/logo.png';
import SmartPhone from '../../assets/icons/smartphone.png';
import AtmMachine from '../../assets/icons/atm-machine.png';
import ChristmasDay from '../../assets/icons/christmas-day.png';
import Store from '../../assets/icons/store.png'
import Driving from '../../assets/icons/driving.png';
import Mail from '../../assets/icons/mail.png';
import LogoGrey from '../../assets/img/logo-grey.png'

const Landing = ({history}) => {
  return (
    <React.Fragment>
    <div
      className="row section img-bg justify-content-center align-items-center p-x-2 m-0"
    >
      <div className="col-lg-8 text-center">
        <img src={Logo} alt="logo" class="logo" />
        <p className="lead">
          Local Ecommerce and Delivery. Made Simple
        </p>
        <hr />
        <p className="sub-lead">
          LocalDelivery.Team offers a flexible, scalable and reliable eCommerce
          platform with fully integrated delivery and marketing modules.
        </p>
      </div>
    </div>

    <div
      className="row section justify-content-center align-items-center p-x-2 m-0"
    >
      <div className="col-lg-8 text-center">
        <p className="title">
          Start your local delivery network in minutes. <br />Our sophisticated
          software enables to you
        </p>
        <div className="row m-top">
          <div className="col text-center">
            <img src={SmartPhone} alt="smartphone" className="icon" />
            <p className="icon-label">
              Take orders online, <br />
              on mobile, on native app
            </p>
          </div>
          <div className="col text-center">
            <img src={AtmMachine} alt="AtmMachine" className="icon" />
            <p className="icon-label">
              Process Payments
            </p>
          </div>
          <div class="col text-center">
            <img src={ChristmasDay} alt="ChristmasDay" className="icon" />
            <p className="icon-label">
              Organise Delivery with <br />
              automated scheduled delivery
            </p>
          </div>
        </div>
        <div className="row m-top">
          <div className="col text-center">
            <img src={Store} alt="store" assName="icon" />
            <p className="icon-label">
              Share Delivery <br />
              with Local Stores
            </p>
          </div>
          <div className="col text-center">
            <img src={Driving} alt="Driving" className="icon" />
            <p className="icon-label">
              Recruit Drivers
            </p>
          </div>
          <div className="col text-center">
            <img src={Mail} alt="Mail" className="icon" />
            <p className="icon-label">
              Email Marketing campaigns
            </p>
          </div>
        </div>
      </div>
    </div>

    <div
      className="row section justify-content-center align-items-center p-x-2 m-0"
      style={{background: "#e5e5e5"}}
    >
      <div className="col-lg-8 d-flex flex-column text-center 
                      align-items-center">
        <p className="title">
          Need a Robust, Flexible Local Delivery Software Platform?
        </p>

        <a
          className="btn orange-bold btn-sm"
          href="/company"
          >Click here to create a test store</a
        >

        <img src={LogoGrey} alt="logo" className="logo m-top" />

        <p className="subtitle">
          A Simple Solution To Complex Requirements
        </p>

      </div>
    </div>
		<div className="foot" >© 2020 Lunch Team. All rights reserved</div>
    </React.Fragment>
  );
}

export default withRouter(Landing);