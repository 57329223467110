import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import './index.css';
import App from './App';
import 'react-toastify/dist/ReactToastify.css';

import ProgressProvider from './context/Progress';
import LocationProvider from './context/Location';
import GlobalStyle from './styles/global';

ReactDOM.render(
<LocationProvider>
    <ProgressProvider>
    <BrowserRouter>
      <GlobalStyle/>
      <ToastContainer autoClose={6000} />
      <App />
    </BrowserRouter>
  </ProgressProvider>
</LocationProvider>, document.getElementById('root'));

