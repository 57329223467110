import React from 'react';

import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete'
import './style.css';

const CategoriesList = ({
  categories,
  onDeleteItem,
  onEditItem
}) => {
  return(
    <table className="table table-striped table-category">
      <thead>
        <tr>
          <th>Category Name</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        {categories.map((item, index) => (
        <tr key={item.id}>
          <td>{item.description}</td>
          <td>
              <EditIcon style={{marginRight: '5%', cursor: 'pointer'}} 
                        onClick={() => onEditItem(index)}/> 
              <DeleteIcon style={{marginRight: '5%', cursor: 'pointer'}} 
                          onClick={() => onDeleteItem(index)}/>
          </td>
        </tr>
        ))}
      </tbody>
    </table>
  )
}

export default CategoriesList;