import React, {useState, useEffect, useContext} from 'react';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';

import Header from '../../component/Header';
import { LocationContext } from '../../context/Location';
import { ProgressContext } from '../../context/Progress';
import useModal from '../../hooks/useModal';
import ProductsList from './ProductsList';
import AddProduct from '../../modal/AddProduct';
import Success from '../../modal/Success';
import Loading  from '../../component/Loading';
import { RouterContainer, AppContainer, App } from '../../styles'; 
import './style.css';

const Products = ({history}) =>{
  const [categories, setCategories] = useState([]);
  const [products, setProducts] =  useState([]);
  const [product, setProduct] =  useState(null);
  const {open, openModal, closeModal}  = useModal();
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const { toggleStep } = useContext(ProgressContext);
  const { clear, location } = useContext(LocationContext);
  
  useEffect(() => { 
    toggleStep(3);
  }, [toggleStep]);

  useEffect(() => {
    const jsonData = JSON.parse(sessionStorage.getItem('jsonData'));
    if(jsonData && jsonData.categories){
      const productsSaved = []; 
      jsonData.categories.forEach(category => 
        (category.products.forEach(product => productsSaved.push({...product, category}))));
      setCategories(jsonData.categories);
      setProducts(productsSaved);
    } 
  }, [])

  const handleSaveProduct = (product) =>{
    const jsonData = JSON.parse(sessionStorage.getItem('jsonData'));
    let lisProductsUpdated;
    if(product.index >= 0){
        lisProductsUpdated = products.map((item, index) => {
        return index === product.index ? 
               {id: product.id,
                description : product.description, 
                price: product.price, 
                category: jsonData.categories
                                  .find(category => (category.id === product.category))
              } : item; 
      });
    }else{
      lisProductsUpdated = products.concat({
        id: product.id,
        description : product.description, 
        price: product.price, 
        category: categories.find(category  => category.id === product.category) 
      });
    }
      setProducts(lisProductsUpdated);
      const categoriesUpdate = jsonData
          .categories
          .map(category => category.id === product.category ? 
                {...category, products: [...category.products, product]} : 
                {...category, products: category.products
                                                .filter(p => p.id !== product.id )});
     
    sessionStorage.setItem('jsonData',
                            JSON.stringify({...jsonData, categories: categoriesUpdate}));
    setProduct(null);
    closeModal();
  }

  const handleDeleteItem = (index) =>{
    const product = products.find((_, indexItem) => indexItem === index);
    const jsonData = JSON.parse(sessionStorage.getItem('jsonData'));
    const categoriesUpdate = jsonData
          .categories
          .map(category => category.id === product.category.id ? 
                {...category, products: [...category.products.filter(p => p.id !== product.id)]} : 
                category );
     
    sessionStorage.setItem('jsonData',
                            JSON.stringify({...jsonData, categories: categoriesUpdate}));
    setProducts(products.filter(
      (_, indexItem) => indexItem !== index)
    );
  }

  const handleEditItem = (index) => {
    const product = products.find(
      (_, indexItem) => indexItem === index
    );
    setProduct({...product , index});
    openModal();
  }

  const handleCloseModal = () =>{
    closeModal();
    setProduct(null);
  }
  const handleCloseSuccess = () => {
    setSuccess(false);
    clear();
    history.push('/');
  }

  const mountData = () => {
    const jsonData = JSON.parse(sessionStorage.getItem('jsonData'));
    const categoriesJson = jsonData.categories.map(item => (
        {name: item.description, 
        products : item.products.map(item => 
        ({name: item.description, price: Number(item.price)}))}));
    return {...jsonData, 
            location: location.city,
            country: location.country,
            addressLabel: location.labelLocation,
            address: jsonData.address.address,
            addressGoogle: jsonData.address.address,
            latitude: jsonData.address.lat,
            longitude: jsonData.address.lng, 
            categories: categoriesJson};
  }

  const handleSave = async () =>{
    if(products.length === 0){
      toast.warn('You should add at least one product', {
        position: toast.POSITION.TOP_RIGHT
      });
      return;
    }
    try{
      
      const url = 'https://api.localdelivery.team/menu/v1/business/short';
      const auth =
      "Basic " +
      Buffer.from("backoffice@lunch.team:lunch@dublin").toString("base64");
      // const url = 'http://api-staging.lunch.team/menu/v1/business/short';
      // const auth =
      // "Basic " +
      // Buffer.from("projectxfb@gmail.com:123456").toString("base64");
      setLoading(true);
      const response = await fetch(url, {
        method: 'POST',
        headers: { authorization: auth, "content-type": "application/json" },
        body: JSON.stringify(mountData())
      })
      if (!response.ok) {
        const result = await response.json()
        throw Error(result.message);
      }
      setLoading(false);
      setSuccess(true);
    }catch(error){
      setLoading(false);
      const message = error.message.substr(error.message.lastIndexOf(":") + 1, error.message.length);
      toast.error(message || 'Something went wrong. Try late.', {
        position: toast.POSITION.TOP_RIGHT
      });
    }
  }

  return (
  <App>
    <AppContainer className="appContainer">
      <Header/>
      <RouterContainer>
        <div>
          {
              open && 
                <AddProduct 
                  close={handleCloseModal} 
                  categories={categories}
                  product={product}
                  onSaveProduct={handleSaveProduct} />
            } 
          { success && <Success close={handleCloseSuccess} /> }
          <header className="product-header">
              <h3 className="title">Add Products</h3>
              <button onClick={openModal} 
                      className="btn btn-round orange-bold" 
                      type="submit">
                New Product
              </button>
          </header>
          <React.Fragment>
          {loading && <Loading />}
          <ProductsList 
            products={products} 
            onDeleteItem={handleDeleteItem} 
            onEditItem={handleEditItem} />
          <div className="add-product-footer">
          <button className="btn gray-bold" 
                onClick={() => history.push('/category')} >
              BACK
            </button>
            <button className="btn orange-bold" 
                onClick={handleSave} >
              SAVE
            </button>
          </div>
          </React.Fragment>
        </div>
      </RouterContainer>
    </AppContainer>
  </App>);
}

export default withRouter(Products);