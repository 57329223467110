import React, {createContext, useState} from 'react';

export const ProgressContext = createContext({
  step: 1,
  toggleStep: (step)=>{},
});

const ProgressProvider = ({children}) => {

    const [step, setStep] = useState(1);

    const toggleStep = (step) => {
      setStep(step)
    };

    return (
      <ProgressContext.Provider value={{
        step,
        toggleStep
      }}>
        {children}
      </ProgressContext.Provider>
    );
}

export default ProgressProvider;