import React, {useEffect, useState, useContext} from 'react';
import {withRouter} from 'react-router-dom';
import { toast } from 'react-toastify';

import Header from '../../component/Header';
import { ProgressContext } from '../../context/Progress';
import CategoriesList from './CategoriesList';
import AddCategory from '../../modal/AddCategory';
import useModal from '../../hooks/useModal';
import { RouterContainer, AppContainer, App } from '../../styles'; 
import './style.css';

const Categories = ({history}) => {
  const {open, openModal, closeModal}  = useModal();
  const [listCategories, setListCategories] = useState([]);
  const [category, setCategory] = useState(null);
  const { toggleStep } = useContext(ProgressContext);

  useEffect(() => {
    const jsonData = JSON.parse(sessionStorage.getItem('jsonData'));
    if(jsonData && jsonData.categories){
      setListCategories(jsonData.categories);
    }
  }, [])

  useEffect(() => {
    toggleStep(2);
  }, [toggleStep])
  
  const handleSaveCategory = category => {
    if(category.id){
      let listCategoriesUpdated = listCategories.map((item) => {
        return item.id === category.id ? 
               {...item, description: category.description} : item; 
      });
      setListCategories(listCategoriesUpdated);
    }else{
      const listCategoriesUpdate = listCategories.concat(
        {id: generateID(), 
          description: category.description, products: []});
      setListCategories(listCategoriesUpdate);
    }
    setCategory(null);
    closeModal();
  }

  const generateID = () => {
    return '_' + Math.random().toString(36).substr(2, 9);
  };

  const handleEditItem = (index) => {
    const category = listCategories.find(
      (_, indexItem) => indexItem === index
    );
    setCategory(category);
    openModal();
  }

  const handleDeleteItem = (index) => {
    setListCategories(listCategories.filter(
      (_, indexItem) => indexItem !== index)
    );
  }

  const handleBack = () => {
    const jsonData = JSON.parse(sessionStorage.getItem('jsonData'));
    const categories = listCategories.map(item => {
      const cat = jsonData.categories
              .find(category => category.id === item.id);
      return {...item, 
              description: item.description || cat.description }
    }); 
    sessionStorage.setItem('jsonData', JSON.stringify({...jsonData, categories}));
    history.push('/company')
  }

  const handleAddProducts = () => {
    if(listCategories.length === 0){
      toast.warn('You should add at least one category', {
        position: toast.POSITION.TOP_RIGHT
      });
      return;
    }
    const jsonData = JSON.parse(sessionStorage.getItem('jsonData'));
    const categories = listCategories.map(item => {
      const cat = jsonData.categories
              .find(category => category.id === item.id);
      return {...item, 
              description: item.description || cat.description }
    }); 
    sessionStorage.setItem('jsonData', JSON.stringify({...jsonData, categories}));
    history.push('/product');
  }

  return (
    <App>
      <AppContainer className="appContainer">
        <Header />
        <RouterContainer>
          <div className="category-container">
            {open && 
            <AddCategory
              category={category}
              close={closeModal} 
              onSaveCategory={handleSaveCategory} />}
              <header className="category-header">
                <h3 className="title">Add Menu Categories</h3>
                <button onClick={openModal} 
                        className="btn btn-round orange-bold" 
                        type="submit">
                  New Category
                </button>
              </header>
              <React.Fragment>
                <CategoriesList 
                    categories={listCategories} 
                    onEditItem={handleEditItem}
                    onDeleteItem={handleDeleteItem} />
                <div className="add-category-footer">
                  <button className="btn gray-bold" 
                          onClick={handleBack}>
                    BACK
                  </button>
                  <button className="btn orange-bold" 
                          onClick={handleAddProducts} >
                    NEXT
                  </button>
                </div>
              </React.Fragment> 
          </div>
        </RouterContainer>
      </AppContainer>
    </App>
  )
}

export default withRouter(Categories);