import React from 'react';

import {IconBox} from './styles';
import { ModalOverlay, BtnGreen } from '../styles';
import Done from '@material-ui/icons/Done';

const Success = ({
    close
  }) => {

    return (
        <ModalOverlay>
            <div style={{display : 'block'}} className="modal show" id="myModal">
                <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div style={{background: "#47c9a2"}} className="modal-header">
                    <IconBox>
                        <Done style={{width: "60px", height: "60px" }} />
				    </IconBox>
                    <button onClick={close} 
                            style={{marginLeft: "0"}}
                            type="button" 
                            className="close" 
                            data-dismiss="modal">&times;
                    </button>
                    </div>
                    <div className="modal-body text-center">
                        <h4>Great!</h4>
                        <p>
                        Thanks for creating your business - 
                        please check your email on how customers can view your store online and add more menu items
                        </p>
                    </div>
                    <div className="add-category-footer">
                        <BtnGreen onClick={close} >
                            CLOSE
                        </BtnGreen>
                    </div>          
                </div>
                </div>
            </div> 
        </ModalOverlay>
    );
}

export default Success;

