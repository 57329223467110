import React, {lazy, Suspense} from 'react';
import {Switch, Route } from 'react-router-dom';

import Loading from './component/Loading';
import Landing from './container/Landing';
import Company from './container/Company';
import Categories from './container/Categories';
import Products from './container/Products';
import NotFoundPage from './container/NotFoundPage';

const CheckUser = lazy(() => import('./container/CheckUser'));
const CompanyUsersDetails = lazy(() => import('./container/CompanyUsersDetails'));



export default function Routes(){
    return (
        <Suspense fallback={<Loading />}>
            <Switch>
                <Route exact path="/" component={Landing} />
                <Route exact path="/company" component={Company} />
                <Route exact path="/category" component={Categories} />
                <Route exact path="/product" component={Products} />
                <Route exact path="/check-user" render={() => <CheckUser />} />
                <Route exact path="/company-users-details" render={() => <CompanyUsersDetails />} />
                <Route path="*" component={NotFoundPage} />
            </Switch>
        </Suspense>
    );
}