import React, {useState, useContext, useEffect} from 'react';
import {withRouter} from 'react-router-dom';

import Header from '../../component/Header';
import { ProgressContext } from '../../context/Progress';
import { LocationContext } from '../../context/Location';
import { useForm } from '../../hooks/useForm';
import validateCompay from '../../validations/company';
import PhoneInput from 'react-phone-number-input';
import LocationSearchInput from '../../component/LocationSearchInput';
import CurrencyInput from '../../component/CurrencyInput';
import { RouterContainer, AppContainer, App } from '../../styles'; 
import './style.css';


const Company = ({history}) => {
  
  const [categories, setCategories] = useState([]);
  const { toggleStep } = useContext(ProgressContext);
  const { location } = useContext(LocationContext);

  useEffect(() => {
    toggleStep(1);
  }, [toggleStep]);

  useEffect(() => {
    const fetchCategories = async() => {
      const url = 'https://api.localdelivery.team/menu/v1/category/';
      const auth =
      "Basic " +
      Buffer.from("backoffice@lunch.team:lunch@dublin").toString("base64");
      // const url = 'http://api-staging.lunch.team/menu/v1/category/';
      // const auth =
      // "Basic " +
      // Buffer.from("projectxfb@gmail.com:123456").toString("base64");
      try{
        const response = await fetch(url, {
          headers: { authorization: auth, "content-type": "application/json" },
          });
        if(!response.ok){
          const result = await response.json();
          throw Error(result.message);  
        }
        const result = await response.json();
        setCategories(result || []);
      }catch(error){

      }
    }
    fetchCategories();
  }, [setCategories]);

  const loadInitialState = () => {
    const jsonData = JSON.parse(sessionStorage.getItem('jsonData'));
    if(jsonData){
      return {name : jsonData.name, phone: jsonData.phone, email: 
              jsonData.email, 
              address: jsonData.address, 
              category: jsonData.categoryId, website: jsonData.website, 
              taxDelivery : jsonData.taxDelivery}
    }
    return {name : '', phone: '', email: '', 
            address: {address: '', lat: '', lng: ''}, category: 0,  
            website: '', taxDelivery : 0}
  }

  const addCompany =() =>  {
    toggleStep(2);
    const jsonData = JSON.parse(sessionStorage.getItem('jsonData'));
    const categories = jsonData && jsonData.categories ? jsonData.categories : [];
    sessionStorage.setItem('jsonData', 
    JSON.stringify({
      name: values.name,
      phone: values.phone,
      email: values.email,
      website: values.website,
      taxDelivery: Number(values.taxDelivery),  
      address: values.address,
      categoryId: Number(values.category),
      categories
      }));
    history.push("/category");
  };

  const { values, errors, onChange, onSubmit } = 
  useForm(
    addCompany, 
    loadInitialState(), 
    validateCompay
  );

  return (
    <App>
      <AppContainer className="appContainer">
        <Header/>
        <RouterContainer>
          <h3 >Register your Business</h3>
          <form onSubmit={onSubmit}>
            <div className="form-row">
              <div className="form-group col-md-8 required">
                <label className="control-label" >Business Name</label>
                <input
                  type="text"
                  className={`form-control ${errors.name && "is-invalid"}`}
                  name="name"
                  value={values.name}
                  onChange={onChange}
                />
                {errors.name && (
                  <div className="invalid-feedback">{errors.name}</div>
                )}
              </div>
              <div className="form-group col-md-4 required">
                <label className="control-label" >Business Email</label>
                <input
                  type="email"
                  className={`form-control ${errors.email && "is-invalid"} `}
                  name="email"
                  value={values.email}
                  onChange={onChange}
                />
                {errors.email && (
                  <div className="invalid-feedback">{errors.email}</div>
                )}
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-8 required">
                <label className="control-label" >Address</label>
                <LocationSearchInput  
                  error={errors.address}
                  address={values.address} 
                  onSelectAddress={onChange} />
              </div>
              <div className="form-group col-md-4 required">
                <label className="control-label" >Phone</label>
                <PhoneInput
                  international
                  defaultCountry={location.countryCode} 
                  value={values.phone}
                  onChange={(value) => onChange({target:{name : 'phone', value}})} />
                {errors.phone && (
                  <div style={{display:'block'}} className="invalid-feedback">
                    {errors.phone}
                  </div>
                )}          
              </div>
            </div> 
            <div className="form-row">
              <div className="form-group col-md-4 required">
                <label className="control-label">Company Category</label>
                <select 
                  className={`form-control ${errors.category && "is-invalid"} `}
                  name="category"
                  onChange={onChange}
                  value={values.category}>
                  <option></option>
                  {
                    categories.map(item => (
                    <option key={item.id} value={item.id}>{item.name}</option>))
                  }
                </select>
                {errors.category && (
                  <div style={{display:'block'}} className="invalid-feedback">
                    {errors.category}
                  </div>
                )}
              </div>
              <div className="form-group col-md-4">
                <label>Website</label>
                <input
                  type="text"
                  className="form-control"
                  name="website"
                  value={values.website}
                  onChange={onChange}
                />
              </div>
              <div className="form-group col-md-4">
              <label>Local Delivery Fee </label>
                <CurrencyInput className="currencyInput" currency={location.currency}>
                  <input
                    type="number"
                    step="any"
                    className="form-control"
                    name="taxDelivery"
                    value={values.taxDelivery}
                    onChange={onChange}
                  />
                </CurrencyInput>
              </div>
            </div>
            <div className="form-footer">
              <button className="btn orange-bold" type="submit">
                NEXT
              </button>
            </div>
          </form>
        </RouterContainer>
      </AppContainer>
    </App>
  );
}

export default withRouter(Company);