export default function validateProduct(values){
  let errors = [];

  if(values.description.trim() === ''){
    errors.description = "Please complete this required field.";
  }

  if(values.category === 0){
    errors.category = "Please complete this required field.";
  }

  if(values.price === 0){
    errors.price = "Please complete this required field.";
  }
  return errors;
}