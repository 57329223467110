import styled from 'styled-components';

export const ModalOverlay = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1000;
    background-color: rgba(0, 0, 0, .65);
`;


export const BtnGreen = styled.button`
    background: #28a745;
    color: white;
    padding: 9px;
    font-size: 14px;
    border-radius: 5px;
    width: 25%;
    border:1px solid white;
    transition: all ease 0.3s;

    &:hover{
        color: #fff;
        background-color: #1e7e34;
        border-color: #1c7430
    }
`

export const BtnOrange = styled.button`
	background: #ff5007;
	color: white;
	padding: 9px;
	font-size: 14px;
	border-radius: 5px;
	width: 25%;
	border:1px solid white;
	transition: all ease 0.3s;
    
    &:hover{
        background: white;
        color: #ff5007;
        border: 1px solid #ff5007;
    }
`
