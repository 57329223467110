import React, {useContext} from 'react';

import { LocationContext } from '../../../context/Location';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete'

const ProductsList = ({products, onEditItem, onDeleteItem}) => {

  const { location } = useContext(LocationContext);

  return (
    <table className="table table-striped table-category">
      <thead>
        <tr>
          <th>Category Name</th>
          <th>Product Name</th>
          <th>Retail Price</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        {products.map((item, index) => (
        <tr key={index+1}>
          <td style={{maxWidth: "30px", wordWrap: "break-word"}}>{item.category.description}</td>
          <td style={{maxWidth: "30px", wordWrap: "break-word"}}>{item.description}</td>
          <td style={{maxWidth: "30px", wordWrap: "break-word"}}>{`${location.currency} ${item.price}`}</td>
          <td style={{maxWidth: "30px", wordWrap: "break-word"}}>
              <EditIcon style={{marginRight: '5%', cursor: 'pointer'}} 
                        onClick={() => onEditItem(index)}/> 
              <DeleteIcon style={{marginRight: '5%', cursor: 'pointer'}} 
                          onClick={() => onDeleteItem(index)}/>
          </td>
        </tr>
        ))}
      </tbody>
    </table>
  )
}

export default ProductsList;