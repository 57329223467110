import React, {useContext} from 'react';
import './style.css';

import { ProgressContext } from '../../context/Progress';

const Progress = () => {

  const { step } = useContext(ProgressContext);

  return (
    <div className="progress-container" >
      <ul>
        <div className="progress-step" >
          <li className={step >= 1 ? `active` : ''}>1</li>
          <p>Register</p> 
        </div>
        <div className="progress-step" >
          <li className={step >= 2 ? `active` : ''}>2</li>
          <p>Add Categories</p> 
        </div>
        <div className="progress-step">
          <li className={step >= 3 ? `active` : '' }>3</li>
          <p>Add Products</p> 
        </div>
      </ul>  
    </div> 
  );
} 

export default Progress;