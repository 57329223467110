import styled from 'styled-components';

import background from '../assets/img/easy-company.jpg';

export const App = styled.div`
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    position: absolute;
    top: 0; right: 0; left: 0; bottom: 0;
    align-items: center;
    background: url(${background}) !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
`;

export const AppContainer = styled.div`
    width: 60%;
    margin: 3% 0;
    background-color: white;
    border-radius: 1%;
    overflow-y: auto;
`;

export const RouterContainer = styled.div`
    display: flex;
    align-content: center;
    flex-direction: column;
    padding: 2% 3%;
    

    h3 {
        margin: 1% auto 3%;
        color: #ff5006;
    }
`; 
