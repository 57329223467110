  /* eslint-disable no-undef */
import React, { useState, useContext , useEffect} from 'react';

import { LocationContext } from '../../context/Location';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import './style.css';

const LocationSearchInput = ({error, bgColor, onSelectAddress, address}) => {
  
  const [selectedAddress, setAddress] = useState(address);
  const { handleSetLocationInfo } = useContext(LocationContext);
  const [listLabelLocation, setListLabelLocation] = useState([]);
  
  useEffect(() => {
    const fetchLocationLabel = async() => {
      const auth =
      "Basic " +
      Buffer.from("backoffice@lunch.team:lunch@dublin").toString("base64");
      const url = "https://api.localdelivery.team/menu/v1/localdelivery/location/";
      // const url = "http://api-staging.lunch.team/menu/v1/localdelivery/location/";
      // const auth =
      // "Basic " +
      // Buffer.from("projectxfb@gmail.com:123456").toString("base64");
      try{
        const response = await fetch(url,{
          headers: { authorization: auth, "content-type": "application/json" },
        });
        if(!response.ok){
          const result = await response.json();
          throw Error(result.message);  
        }
        const result = await response.json();
        setListLabelLocation(result);
      }catch(error){

      }
      
    } 
    fetchLocationLabel();
    
  }, [])
  
  const handleChangeAddress = address => {
    setAddress({address, lat: '', lng: ''});
  };

  const getCity = (result) => {
    const city = result.address_components
    .find(item => item.types.some(
      type =>  ['locality', 'administrative_area_level_2', 'postal_town']
      .indexOf(type) !== -1));
    return city.long_name;
  }

  const getCountry = (result) =>{
    const item =  result.address_components
    .find(item => item.types.some(type => type === 'country'));
    return  item;
  }

  const getLabelLocation = (result) => {
    let labelLocation  = 'trial';
    listLabelLocation.forEach( ld => {
      let label = ld.label.toLowerCase();
      let description = ld.description.toLowerCase();
      let component = result
      .find( component => component.short_name.toLowerCase().includes(label) || 
                          component.long_name.toLowerCase().includes(description));
      if(component){
        labelLocation = description;
        return;
      }
    });
    return labelLocation;
  }

  const handleSelectAddress = address => {
    geocodeByAddress(address)
      .then(results => {
        handleSetLocationInfo(getCountry(results[0]), 
                              getLabelLocation(results[0].address_components), 
                              getCity(results[0]));
        return getLatLng(results[0])
      })
      .then(latLng => {
        const { lat, lng } = latLng;
        setAddress({lat, lng, address});
        onSelectAddress({target:{name : 'address', value: {lat, lng, address}}});
      })
      .catch(error =>{ 
        console.error('Error', error)
      });
  };

  return (
    <PlacesAutocomplete
      value={selectedAddress.address}
      onChange={handleChangeAddress}
      onSelect={handleSelectAddress}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps}) => (
        <div className="form-group required">
          <input
            autoComplete="off"
            {...getInputProps({
              className: 'form-control location-search-input',
              style: { width: '100%', backgroundColor: bgColor },
            })}
          />
          <div className='autocomplete-dropdown-container'>
            {suggestions.map(suggestion => {
              const className = suggestion.active
                ? 'suggestion-item--active'
                : 'suggestion-item';

              const style = suggestion.active
                ? {
                    cursor: 'pointer',
                    backgroundColor: 'white',

                    color: '#ff5007',
                    fontWeight: 'bold',
                    height: '40px',
                  }
                : {
                    cursor: 'pointer',
                    backgroundColor: 'white',

                    fontColor: '#ff5007',
                    color: '#ff3807',
                    backgroundColor: 'white',
                    height: '100%',
                    paddingTop: '2%',
                    paddingBottom: '2%',
                    
                  };
              return (
                <div
                  {...getSuggestionItemProps(suggestion, {
                    className,
                    style,
                  })}
                >
                  <span style={{ marginTop: '3%' }}>
                    {suggestion.description}
                  </span>
                </div>
              );
            })}
          </div>
          {!!error && (
            <div style={{display: 'block'}} 
                 className="invalid-feedback">{error}
            </div>
          )} 
        </div>
      )}
    </PlacesAutocomplete>
  );
};

export default LocationSearchInput;
